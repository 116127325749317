import "./ContactUs.css";

import gsap from "gsap";
import { useLayoutEffect } from "react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const ContactUs = () => {
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      lazy: false,
      scrollTrigger: {
        trigger: ".contactUs",
        start: "top 30%", // when the top of the trigger hits the top of the viewport
      },
    });

    tl.to(".contactUs-content > *", {
      duration: 1,
      opacity: 1,
      y: 0,
      ease: "power2.out",
      stagger: 0.1,
    });
  }, []);
  return (
    <>
      <div className="contactUs">
        <div className="contactUs-box">{/* box here */}</div>
        <div className="contactUs-content">
          <h2>Lets turn talk into action</h2>
          <p>hello@pixtec.oc.uk</p>
          <div className="contactUs-button-wrapper">
            <button>Book a call</button>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="contactUs-logo">
        <svg
          width="123"
          height="38"
          viewBox="0 0 123 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66602 25.7953V37.5842H0.233154V6.51562H6.54231V9.27864C8.15053 7.25242 10.501 6.08582 13.1607 6.08582C19.0988 6.08582 23.4286 10.6294 23.4286 17.5063C23.4286 24.3217 19.1606 28.9268 13.1607 28.9268C10.501 28.8653 8.27424 27.7601 6.66602 25.7953ZM17.1813 17.4449C17.1813 14.0065 14.9545 11.6732 11.9237 11.6732C8.89278 11.6732 6.66602 13.9451 6.66602 17.4449C6.66602 20.9447 8.89278 23.2165 11.9237 23.2165C14.9545 23.2165 17.1813 20.9447 17.1813 17.4449Z"
            fill="white"
          />
          <path
            d="M32.7068 6.51569H26.2739V28.4356H32.7068V6.51569Z"
            fill="white"
          />
          <path
            d="M98.025 19.0414H81.881C82.4377 22.1114 84.1696 23.6464 87.0768 23.6464C89.118 23.6464 90.4169 22.971 91.0355 22.1728H97.5302C96.1694 26.2866 92.3344 28.8654 86.8912 28.8654C80.3965 28.8654 75.6956 24.199 75.6956 17.4449C75.6956 10.6909 80.3965 6.02448 86.8912 6.02448C93.3859 6.02448 98.0869 10.6909 98.0869 17.4449C98.0869 18.0589 98.025 18.6729 98.025 19.0414ZM81.9429 15.9099H91.9014C91.3447 12.7785 89.6128 11.3049 86.8912 11.3049C84.2315 11.3049 82.4377 12.7171 81.9429 15.9099Z"
            fill="white"
          />
          <path
            d="M111.509 11.7346C108.54 11.7346 106.437 14.0065 106.437 17.5063C106.437 21.0061 108.54 23.2779 111.509 23.2779C113.922 23.2779 116.087 21.8657 116.458 19.1027H122.767C122.458 24.9971 117.88 28.9268 111.447 28.9268C104.953 28.9268 100.128 24.2603 100.128 17.5063C100.128 10.7522 104.953 6.08582 111.447 6.08582C117.695 6.08582 122.148 9.83124 122.705 15.9099H116.396C116.087 12.9627 113.86 11.7346 111.509 11.7346Z"
            fill="white"
          />
          <path
            d="M42.9127 6.51569H42.1705H35.7376H35.1809L42.789 17.4449L35.1809 28.497H42.9127L46.6858 23.0938L50.459 28.4356H51.0157H57.3867H58.2526L42.9127 6.51569Z"
            fill="white"
          />
          <path
            d="M55.531 6.51569H49.16V12.8399H55.531V6.51569Z"
            fill="white"
          />
          <path
            d="M73.0358 22.5411V28.6198C72.7266 28.6198 67.1596 28.6198 67.1596 28.6198C66.7885 28.6198 66.4174 28.5584 66.0463 28.497C63.8814 28.0058 62.335 26.1024 62.335 23.8305V16.8309V12.8399H59.1804V6.51564H62.335V0.00720215H68.7679V6.51564H73.0358V12.8399H68.7679V21.1289C68.7679 21.8657 69.3864 22.5411 70.1905 22.5411H73.0358Z"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
};

export default ContactUs;
