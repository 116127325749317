import { Pill } from "../UI/Pill";
import "./WhatWeDo.css";

const PILL_DATA_ACCENT = [
  { text: "Engineering" },
  { text: "Design Systems" },
  { text: "User Experience" },
  { text: "React" },
  { text: "TypeScript" },
  { text: "Use Interface" },
  { text: "Branding" },
] as const;

const PILL_DATA_DEFAULT = [
  { text: "Websites" },
  { text: "Webflow" },
  { text: "Figma" },
  { text: "WebGL" },
  { text: "Wordpress" },
  { text: "Git" },
  { text: "Framer" },
  { text: "AWS" },
  { text: "Firebase" },
  { text: "React Native" },
];

const WhatWeDo = () => {
  return (
    <div className="whatWeDo-container">
      <div className="whatWeDo-title">
        <h2>
          We do it all. <br />
          <span>And more.</span>
        </h2>
      </div>
      <div className="whatWeDo-pills-section">
        <div className="whatWeDo-pills">
          <div className="whatWeDo-pills--accent">
            {PILL_DATA_ACCENT.map((pill) => (
              <Pill text={pill.text} style={`accent`} />
            ))}
          </div>
          <div className="whatWeDo-pills--default">
            {PILL_DATA_DEFAULT.map((pill) => (
              <Pill text={pill.text} style={`default`} />
            ))}
          </div>
        </div>
        <div style={{ marginTop: "20px" }}></div>
        <p className="whatWeDo-pills-excerpt">
          We add to this list every other day. If you require something not
          listed, please don’t hesitate to get in touch.
        </p>
      </div>
    </div>
  );
};
export default WhatWeDo;
