import React, { forwardRef, Ref } from "react";
import "./Box.css";

export const Box = forwardRef((props: BoxProps, ref: Ref<HTMLDivElement>) => {
  const { size = 150, color } = props;
  return (
    <div
      ref={ref}
      className="box"
      style={{ width: size, height: size, backgroundColor: color }}
    />
  );
});

export interface BoxProps {
  /**
   * Height/Width of the box
   */
  size: number;

  /**
   * Height/Width of the box.
   */
  color: string;

  /**
   * Height/Width of the box.
   */
  ref?: any;
}
