import WemblyLogo from "./img/wembly-logo.png";
import BTLogo from "./img/bt-logo.png";
import NHSLogo from "./img/nhs-logo.png";
import KingscrossLogo from "./img/kings-cross-logo.png";
import GovLogo from "./img/uk-parliament-logo.png";
import DesignMuseum from "./img/the-design-museum-logo.png";

import "./LogoBanner.css";

const LogoBanner = () => {
  return (
    <div className="logo-banner">
      <p className="logo-banner-pre-text">
        We've had the pleasure of working with
      </p>
      <div className="logo-banner-content">
        <div className="logo-banner-content-container">
          <div className="logo-banner-logo">
            <img src={WemblyLogo} alt="wembly logo" />
          </div>
          <div className="logo-banner-logo">
            <img src={BTLogo} alt="bt logo" />
          </div>
          <div className="logo-banner-logo">
            <img src={NHSLogo} alt="nhs logo" />
          </div>
          <div className="logo-banner-logo">
            <img src={KingscrossLogo} alt="kings cross logo" />
          </div>
          <div className="logo-banner-logo">
            <img src={GovLogo} alt="gov logo" />
          </div>
          <div className="logo-banner-logo">
            <img src={DesignMuseum} alt="design museum logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoBanner;
