import React from "react";
import "./App.css";
import ContactUs from "./components/ContactUs/ContactUs";
import Hero from "./components/Hero/Hero";
import LogoBanner from "./components/LogoBanner/LogoBanner";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";

function App() {
  return (
    <>
      <Hero />
      <WhatWeDo />
      <LogoBanner />
      <ContactUs />
    </>
  );
}

export default App;
