import React, { FC } from "react";
import classNames from "classnames";
import "./Pill.css";

export const Pill: FC<PillProps> = ({ text, style = "default" }) => {
  return (
    <div
      className={classNames({
        pill: true,
        "pill--accent": style === "accent",
      })}
    >
      <div className="pill__text">{text}</div>
    </div>
  );
};

export interface PillProps {
  /**
   * Text to display within `Pill`
   */
  text: string;

  /**
   * Text to display within `Pill`
   */
  style?: "default" | "accent";
}
