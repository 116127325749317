import { useEffect, useLayoutEffect, useRef } from "react";
import { Box } from "../UI/Box";
import "./Hero.css";

import logo from "./img/pixtec-logo.svg";

import gsap from "gsap";

const Hero = () => {
  const boxOneRef = useRef(null);
  const boxTwoRef = useRef(null);
  const boxFourRef = useRef(null);
  const boxFiveRef = useRef(null);

  useLayoutEffect(() => {
    let isLoaded = false;
    let isLoadingAnimationEnd = false;

    // Animation to play
    const entranceAnimation = () => {
      const tl = gsap.timeline({
        lazy: false,
      });
      tl.to(".loading-image", {
        y: -100,
        duration: 0.5,
        ease: "power2.inOut",
      })
        .to(
          ".loading",
          {
            yPercent: -100,
            duration: 1.25,
            ease: "power4.inOut",
          },
          "<"
        )
        .to(
          ".circle",
          {
            delay: 0.2,
            duration: 0.6,
            scale: 1,
            opacity: 0.3,
            ease: "power4.inOut",
          },
          0.5
        )
        .to(
          ".hero-title",
          {
            duration: 1,
            opacity: 1,
            y: 0,
            ease: "power2.out",
          },
          0.9
        )
        .from(
          boxOneRef.current,
          {
            left: "75%",
            top: "13%",
            ease: "power4.inOut",
          },
          0.6
        )
        .from(
          boxTwoRef.current,
          {
            left: "20%",
            top: "18%",
            ease: "power4.inOut",
          },
          1
        )
        .from(
          boxFourRef.current,
          {
            left: "59%",
            top: "71%",
            ease: "power4.inOut",
          },
          1
        )
        .from(
          boxFiveRef.current,
          {
            left: "20%",
            top: "72%",
            ease: "power4.inOut",
          },
          1
        )
        .to(
          ".box",
          {
            duration: 1,
            opacity: 1,
            scale: 1,
            y: 0,
            stagger: 0.2,
            ease: "power2.out",
          },
          0.8
        )
        .to(".box", {
          onComplete: function () {
            document
              .querySelectorAll(".box")
              .forEach((el) => el.classList.add("box-animation"));
          },
        });
    };

    // This animation plays first. for the loading
    const loadingAnimation = () => {
      gsap
        .timeline({
          onComplete: () => {
            isLoadingAnimationEnd = true;
            if (isLoaded) entranceAnimation();
          },
        })
        .fromTo(
          ".loading",
          {
            yPercent: 100,
          },
          {
            yPercent: 0,
            ease: "power3.inOut",
            duration: 1,
          }
        )
        .from(
          ".loading-image",
          {
            y: 80,
            duration: 1,
            ease: "power2.out",
          },
          0.5
        );
    };
    loadingAnimation();

    setTimeout(function () {
      isLoaded = true;
      if (isLoadingAnimationEnd) entranceAnimation();
    }, 1000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="loading" id="loading">
        <img src={logo} alt="pixtec logo" className="loading-image" />
      </div>

      <div className="hero">
        <div className="circle-wrapper">
          <div className="circle circle-1" />
          <div className="circle circle-2" />
        </div>
        <div className="hero-main">
          <div className="hero-content">
            <Box ref={boxOneRef} size={141.71} color={"#233A7B"} />
            <Box ref={boxTwoRef} size={79.32} color={"#233A7B"} />
            <Box ref={boxFourRef} size={116.21} color={"#416FF6"} />
            <Box ref={boxFiveRef} size={46.87} color={"#233A7B"} />
          </div>
          <h1 className="hero-title">
            The <span>digital partner</span> you have been looking for
          </h1>
        </div>
      </div>
    </>
  );
};
export default Hero;
